<template>
  <el-main class="rh-form">
    <h1>Créer un utilisateur</h1>
    <el-row style="width: 436px" v-loading="userLoadStatus == 1">
      <el-col>
        <users-form ref="formUser" />
      </el-col>
    </el-row>
    <el-divider />
    <!-- boutons -->
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" :disabled="isSaving" @click="onSubmit"
        >Enregistrer</el-button
      >
    </div>
  </el-main>
</template>
<script>
import UsersForm from "@/components/UsersForm";
import { trimStringInObj } from "@/fonctions";
export default {
  name: "UsercreateView",
  components: { "users-form": UsersForm },
  data() {
    return {
      isSaving: false,
    };
  },
  created() {
    // vide l'objet user dans le store
    this.$store.commit("user/resetUser");
  },
  computed: {
    userCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.user.userCreateStatus;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.formUser.$refs.formUser.validate((valid) => {
        if (valid) {
          const userdata = this.$store.state.user.user;
          // enlever les espaces avant et après dans les string
          trimStringInObj(userdata);
          // vérifie si un user avec le même email existe déjà
          const email = userdata.email;
          const UsersInBD = this.$store.getters["user/UsersInBD"];
          if (UsersInBD.filter((m) => m[1] == email).length > 0) {
            this.$alert(
              "Un utilisateur est déjà enregistré avec cet email !",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur maj l'enregistrement
            this.$store.dispatch("user/createUser", userdata);
          }
        } else {
          return false;
        }
      });
    },
    onBack() {
      this.$router.push("/users");
    },
  },
  watch: {
    userCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.userCreateStatus == 2) {
        // message succès
        this.$message({
          message:
            "Le nouvel utilisateur a été enregistré. Un email lui a été envoyé.",
          type: "success",
        });

        // retourne à la liste des utilisateurs
        this.$router.push("/users");
      } else if (this.userCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.contratCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.contratCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
