<template>
  <el-form
    class="app-form"
    ref="formUser"
    :model="form"
    :rules="rules"
    :validate-on-rule-change="false"
    label-position="right"
    label-width="126px"
  >
    <!-- Nom d'utilisateur -->
    <el-form-item label="Nom d'utilisateur" prop="name">
      <el-input v-model="form.name" />
    </el-form-item>
    <!-- Email -->
    <el-form-item label="Email" prop="email">
      <el-input v-model="form.email" />
    </el-form-item>
    <!-- Administrateur (désactivé si édite l'user connecté) -->
    <el-form-item label="Administrateur" prop="isAdmin">
      <el-switch
        v-model="form.isAdmin"
        :inactive-value="inactif"
        :active-value="actif"
        :disabled="form.id == authUser.id"
      >
      </el-switch>
    </el-form-item>
    <!--                      -->
  </el-form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UsersForm",
  data() {
    const valideEmail = (rule, value, callback) => {
      if (value) {
        const valStr = value.toString().trim();
        if (!valStr) {
          callback(new Error("Ce champ est obligatoire"));
        } else if (
          /^[A-Z0-9a-z._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/.test(valStr)
        ) {
          callback();
        } else {
          callback(new Error("l'adresse email n'est pas valide"));
        }
      } else {
        callback(new Error("Ce champ est obligatoire"));
      }
    };
    return {
      actif: true,
      inactif: false,
      rules: {
        name: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "blur",
          },
        ],
        email: [{ required: true, validator: valideEmail, trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.user.user;
      },
      set(form) {
        this.$store.commit("user/setUser", form);
      },
    },
  },
};
</script>
